import React, { useState, useImperativeHandle } from "react";

import DistributionaTable from "../DataTables/DistributionaTable";
import EcoColumns from "../DataTables/columns/EcoColumns";

import PieData from "../Charts/PieData";

import axios from "axios";
import reqHeader from "../../../helper/reqHeader";
import Spinner from "../../utils/spinner";

const Economic = React.forwardRef(
  ({ loading, setLoading, selectedState, selectedDistrict }, ref) => {
    const [ecoData, setEcoData] = useState([]);

    useImperativeHandle(ref, () => ({
      async getData() {
        try {
          setLoading(true);
          const url = `${process.env.REACT_APP_URL}/socioeconomicdistrubution`;
          const headers = reqHeader(false, false, false);
          const config = { headers };
          const res = await axios.get(url, config);
          const data = res.data.data;
          setEcoData(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      },
    }));

    const filterData = ecoData?.filter(
      (obj) =>
        obj.state == selectedState?.value &&
        obj.district == selectedDistrict?.value
    );

    const ecoTableData =
      filterData.length > 0
        ? filterData
        : ecoData?.filter((obj) => obj.state == selectedState.value);

    const data02 = [];

    ecoTableData.map((units) => {
      const { Upper, Upper_Middle, Upper_Lower, Middle, Lower_Middle, Lower } =
        units.socioeconomicstatus;
      data02.push({
        name: `Upper`,
        value: Upper,
      });
      data02.push({
        name: `Upper_Middle`,
        value: Upper_Middle,
      });
      data02.push({
        name: `Upper_Lower`,
        value: Upper_Lower,
      });
      data02.push({
        name: `Middle`,
        value: Middle,
      });
      data02.push({
        name: `Lower_Middle`,
        value: Lower_Middle,
      });
      data02.push({
        name: `Lower`,
        value: Lower,
      });
    });

    return (
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : ecoTableData ? (
          <>
            <DistributionaTable
              filterData={ecoTableData}
              columnSet={EcoColumns}
            />
            {selectedDistrict !== null ? (
              <PieData data={data02} targetHours={false} />
            ) : (
              ""
            )}
          </>
        ) : (
          <p>No data fetched yet.</p>
        )}
      </div>
    );
  }
);

export default Economic;
