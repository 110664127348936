import React, { useState, useImperativeHandle } from "react";

import DistributionaTable from "../DataTables/DistributionaTable";
import AgeColumns from "../DataTables/columns/AgeColumns";

import axios from "axios";
import reqHeader from "../../../helper/reqHeader";
import PieData from "../Charts/PieData";

const Age = React.forwardRef(
  ({ loading, setLoading, selectedState, selectedDistrict }, ref) => {
    const [ageData, setAgeData] = useState([]);

    useImperativeHandle(ref, () => ({
      async getData() {
        try {
          setLoading(true);
          const url = `${process.env.REACT_APP_URL}/agedistruibution`;
          const headers = reqHeader(false, false, false);
          const config = { headers };
          const res = await axios.get(url, config);
          const data = res.data.data;
          setAgeData(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      },
    }));

    const filterData = ageData?.filter(
      (obj) =>
        obj.state == selectedState?.value &&
        obj.district == selectedDistrict?.value
    );

    const ageTableData =
      filterData.length > 0
        ? filterData
        : ageData?.filter((obj) => obj.state == selectedState.value);

    const data02 = [];

    ageTableData.map((units) => {
      data02.push({
        name: `20-30 ${units.district}`,
        value: units.ageGroup["20-30"] ?? 0,
      });
      data02.push({
        name: `31-40 ${units.district}`,
        value: units.ageGroup["31-40"] ?? 0,
      });
      data02.push({
        name: `41-50 ${units.district}`,
        value: units.ageGroup["41-50"] ?? 0,
      });
      data02.push({
        name: `51-60 ${units.district}`,
        value: units.ageGroup["51-60"] ?? 0,
      });
      data02.push({
        name: `61-70 ${units.district}`,
        value: units.ageGroup["61-70"] ?? 0,
      });
    });

    return (
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : ageTableData ? (
          <>
            <DistributionaTable
              filterData={ageTableData}
              columnSet={AgeColumns}
            />
            {selectedDistrict !== null ? (
              <PieData data={data02} targetHours={true} />
            ) : (
              ""
            )}
          </>
        ) : (
          <p>No data fetched yet.</p>
        )}
      </div>
    );
  }
);

export default Age;
