import React , { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import { useForm, Controller, get } from "react-hook-form";
import { Link, Redirect, useHistory} from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";
// import QcRejectFile from "./Pop-ups/qc-reject-file"

const schema = yup.object().shape({
  coordinator: yup
    .string()
    .required("Coordinator is required!"),
});

const QcUserList = () => {

  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);

 
  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinator, setCoordinator] = useState([]);
  const [users, setUsersList] = useState([])
  const [roleBaseDetails, setRoleBaseDetails] = useState({});
  const [alllanguages, setAllLanguages] = useState([]);


  useEffect(() => {
    if(userLogin){
      setState(userLogin.user.state)
      setDistrict(userLogin.user.district)
    }

  }, []);
  

  useEffect(() => {
    
    async function getcoordinators() {
      const url = `${process.env.REACT_APP_URL}/getcoordinatorsforqc`;
      const headers = reqHeader(true, true, true);
      //console.log("state, district", state, district)
      const params = {
        state:state,
        district:district,
       };

      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("Coordinator", res.data.data);
          setCoordinators(
            res.data.data.map(( coordinator ) => ({ label: coordinator, value: coordinator }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getcoordinators();
    
  }, [district]); 

   
  const onChangeCoordinator = (e) => {
    setValue('coordinator', e.target.value,  { shouldValidate: true })
    setCoordinator(e.target.value);

    async function getUsers() {
        const url = `${process.env.REACT_APP_URL}/getinteracceptedusers`;
        const headers = reqHeader(true, true, true);
        const params = {state, district,coordinator:e.target.value}
        const config = { params, headers };
        await axios
          .get(url, config)
          .then((res) => {
            setUsersList(res.data.data)
  
         })
          .catch((err) => {});
      }
      getUsers();

    // async function getAllLanguages() {
    //     const url = `${process.env.REACT_APP_URL}/getalllanguages`;
    //     const headers = reqHeader(true, true, true);
    //     //console.log("header", headers)
    //     const config = { headers };
    //     await axios
    //       .get(url, config)
    //       .then((res) => {
    //         //console.log("AllLanguages", res.data.data);
    //         setAllLanguages(
    //           res.data.data.map(( lang ) => ({ label: lang, value: lang }))
    //         );
            
    //       })
    //       .catch((err) => {});
    //   }
    //   getAllLanguages();

    async function getrolebasedetails() {
      const url = `${process.env.REACT_APP_URL}/getrolebasedetails`;
      const headers = reqHeader(true, true, true);
      console.log("header", headers)
      const params = {
        state, district, coordinator:e.target.value
      }
      console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("supervisor role", res.data.data)
          setRoleBaseDetails({"Supervisor":res.data.data.supervisor.length == 0 ? "" : res.data.data.supervisor[0].supervisorName, "TeamLead":res.data.data.teamlead.length == 0 ? "" : res.data.data.teamlead[0].teamleadName, "Manager":  res.data.data.manager.length == 0 ? "" : res.data.data.manager[0].name })
        })
          .catch((err) => {});
    }
    getrolebasedetails();

  }
  
  // const onChangeDistrictLanguage = (e, fid) => {
  //   setValue('districtlanguage', e.target.value,  { shouldValidate: true })
  //   //setFileLang(e.target.value);
  //   //console.log("e.target.value", e.target.value)
  // }


  const rowClicked = (mobile, speakerID) => {
    //console.log("mobile, speakerID", mobile, speakerID);
    history.push({ pathname : "/qc-userfilelist",
    state: {mobile:mobile, speakerID:speakerID}
  })
  };



  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "speakerID",
        accessor: "speakerID",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return  <span style={{textDecoration: "underline #22A4EE",cursor: "pointer", color:"#22A4EE"}}
          onClick={() => rowClicked(row.original.mobile, row.original.speakerID)}>
          {row.original.speakerID}
        </span>
        },
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        //sticky: "left",
      },
      {
        Header: "Pending Files",
        accessor: "qcPendingCount",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Gender",
        accessor: "gender",
        sortType: "basic",
        filter: "text",
      },
      // {
      //   Header: "Select Correct Gender",
      //   //accessor: "recordedOn",
      //   sortType: "basic",
      //   filter: "text",
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div>
      //         <div className = {`inputWrap ${
      //                    errors.gender ? "error" : ""
      //                 }`}>
      //                 <select
      //                   {...register("gender")}
      //                   onChange={(e) => setValue('gender', e.target.value,  { shouldValidate: true })} // Using setValue
      //                 >
      //                   <option value="">Please select Gender</option>
      //                   <option value="Male">Male</option>
      //                   <option value="Female">Female</option>
      //                 </select>
      //                   {errors.gender && (
      //                   <span className="inputErrorMsg">
      //                     {errors.gender?.message}
      //                   </span>
      //                 )}
      //         </div>
      //       </div>
      //     )
      //   },
      // },
      {
        Header: "Language",
        accessor: "language",
        sortType: "basic",
        filter: "text",
      },
      // {
      //   Header: "District Languages",
      //   //accessor: "recordedOn",
      //   sortType: "basic",
      //   filter: "text",
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div>
      //         <div className = {`inputWrap ${
      //                    errors.districtlanguage ? "error" : ""
      //                 }`}>
      //                 <select
      //                     {...register("districtlanguage")}
      //                     onChange={(e) => setValue('districtlanguage', e.target.value,  { shouldValidate: true })} // Using setValue
      //                     //onChange={(e) => onChangeDistrictLanguage(e, row.original._id)}
      //                   >
      //                     {}
      //                      <option value="">Please select correct language</option>
      //                     {alllanguages && alllanguages.map((lang)=>{
      //                       return <option key={lang.label} value={lang.value}>{lang.label}</option>
      //                     })}
      //                   </select>
      //                   {errors.districtlanguage && (
      //                   <span className="inputErrorMsg">
      //                     {errors.districtlanguage?.message}
      //                   </span>
      //                 )}
      //         </div>
      //       </div>
      //     )
      //   },
      // },
      // {
      //   Header: "Update",
      //   //accessor: "qcaccept",
      //   sortType: "basic",
      //   filter: "text",
      //   Cell: ({ value, column, row }) => {
      //     return(
      //       <div>
      //       <button
      //         id={`acpbtn${row.original._id}`}
      //         onClick={(e) => onQCUpdateGenderLanguage(row.original._id, row.original.language, row.original.gender, row.original.speakerID, row.original.mobile, e)}
      //         className="btn blueBtn-fill"
      //       >
      //         Update
      //       </button>
      //     </div>
      //     )
      //   },
      // },
      // {
      //   Header: "Sign Off",
      //   accessor: "",
      //   sortType: "basic",
      //   filter: "text",
      //   //fixed: "left",
      //   Cell: ({ value, column, row }) => {
      //     //console.log("row", row)
      //     // var specific_date = new Date('2022-05-06').getTime(); //need to change date
      //     // var createdOn = new Date(row.original.createdOn.split("T")[0]).getTime();
      //     // console.log("createdOn", createdOn)
      //     return (
      //       // createdOn > specific_date ?
      //       <button className="btn darkGreenBtn" disabled={row.original.isQcSignedOff || row.original.autoSignOff} onClick={(e) => QcSignOffSpeaker(row.original)}>Sign Off</button>
      //     );
      //   },
      // },
   
    ],
  );


//   const onQCUpdateGenderLanguage = async (uid, originalLang, originalGender, speakerid, mobile, e) => {
      
//     let selectedLang = getValues("districtlanguage");
//     let selectedgender = getValues("gender");
//     if(selectedLang || selectedgender){
//       // let acbtn = document.getElementById(e.target.id);
//       // acbtn.disabled = true;
//       // let rejectbtn = document.getElementById(`rejbtn${fid}`);
//       // rejectbtn.disabled = true;
  
//       const headers = reqHeader(true, true, true);

//       //console.log("fileLang inside", selectedLang, fid)
  
//       let body = {
//         id: uid,
//         language: selectedLang ? selectedLang: originalLang,
//         gender: selectedgender ? selectedgender: originalGender,
//         speakerID: speakerid,
//         mobile: mobile
//       };

//       console.log("body", body)
  
//       const config = { headers };
//       let url = `${process.env.REACT_APP_URL}/qclanguagegenderupdateuser`;
//       await axios.put(url, body, config).then((res) => {
//         toast.success(res.data.msg);
//         window.location.reload();
//       });
//     }else{
//       toast.error("Please select correct language!")
//     }


// };



//   const QcSignOffSpeaker = async(row) => {

//     //let recordedMinutes = row.recordedHours.split(":"); //if(recordedMinutes[1] < 5)
//     // console.log("recordedMinutes", recordedMinutes)
//     //console.log("qcPendingCount, qcAcceptedCount",row.qcPendingCount, row.qcAcceptedCount)
//     if(row.qcPendingCount > 0){
//       toast.error("Please complete QC for all the files for Signing off Speaker!")
//     }else if(row.qcAcceptedCount < 10){
//       toast.error("Minimum 10 files required for Signing Off Speaker!")
//     }
//     else{       
//       const url = `${process.env.REACT_APP_URL}/updateqcspeakersignoff`;
//       const headers = reqHeader(true, true, true);
//       //console.log("header", headers)
//       const body = {
//         mobile: row.mobile, speakerID: row.speakerID, userID: row._id
//       }
//       //console.log("body", body)
//       const config = { headers };
//       await axios
//         .put(url, body, config)
//         .then((res) => {
//           if(res.data.success){
//             toast.success("Speaker signedoff successfully!")
//             setTimeout(() => {
//               window.location.reload();
//             }, 3000);
//           }
//         })
//           .catch((err) => {
//             toast.error(err.response.data)
//           });
//       }

// }


    return (
      <>
        <div className="container entitiCustomerWrap d-flex">
          <LeftNavigation />
          <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Quality Checker
              </div>

              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between regionSelectWrap">
                <div className="regionselect d-flex">
                    <div className = {`inputWrap ${
                         errors.coordinator ? "error" : ""
                      }`}>
                      <select
                          {...register("coordinator")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeCoordinator(e)}
                        >
                          {}
                           <option value="">Please select Coordinator</option>
                          {coordinators && coordinators.map((coordinator)=>{
                            console.log("Option coordinator", coordinator.value)
                            return <option key={coordinator.value} value={coordinator.value}>{coordinator.label}</option>
                          })}
                        </select>
                        {errors.coordinator && (
                        <span className="inputErrorMsg">
                          {errors.coordinator?.message}
                        </span>
                      )}
                    </div>
                </div>
              </div>

              <div className="d-flex justify-between">
                <div className="adminDetails">
                <div>
                    <label>State:</label>
                    <span className="value">{state}</span>
                  </div>
                  <div>
                    <label>District:</label>
                    <span className="value">{district}</span>
                  </div>
                  {/* <div>
                    <label>Manager Name:</label>
                    <span className="value">{roleBaseDetails.Manager}</span>
                  </div>
                  <div>
                    <label>Team Lead Name:</label>
                    <span className="value">{roleBaseDetails.TeamLead}</span>
                  </div> */}
                  <div>
                    <label>Supervisor Name:</label>
                    <span className="value">{roleBaseDetails.Supervisor}</span>
                  </div>
                  <div>
                    <label>Co-Ordinator Name:</label>
                    <span className="value">{coordinator}</span>
                  </div>
                  
                  {/* <div>
                    <label>Total Audio Recorded:</label>
                    <span className="value">{totalDuration}</span>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="projectCoordinatorContent">
              <div className="projectContent mobileDashboard">
              <Table
                      columns={columns}
                      data={users}
                      count={users.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="users"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default QcUserList;