import { convertSecondsToTime } from "../../convert";

const AgeColumns = (data) => {
  const COLUMNS = [
    {
      Header: "State",
      Footer: "Total",
      accessor: "state",
    },
    {
      Header: "District",
      accessor: "district",
    },
    {
      Header: "20-30",
      accessor: "ageGroup.20-30",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data
            .map((obj) => obj.ageGroup["20-30"])
            .reduce((total, val) => total + (val || 0), 0)
        ),
    },
    {
      Header: "31-40",
      accessor: "ageGroup.31-40",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data
            .map((obj) => obj.ageGroup["31-40"])
            .reduce((total, val) => total + (val || 0), 0)
        ),
    },
    {
      Header: "41-50",
      accessor: "ageGroup.41-50",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data
            .map((obj) => obj.ageGroup["41-50"])
            .reduce((total, val) => total + (val || 0), 0)
        ),
    },
    {
      Header: "51-60",
      accessor: "ageGroup.51-60",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data
            .map((obj) => obj.ageGroup["51-60"])
            .reduce((total, val) => total + (val || 0), 0)
        ),
    },
    {
      Header: "61-70",
      accessor: "ageGroup.61-70",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data
            .map((obj) => obj.ageGroup["61-70"])
            .reduce((total, val) => total + (val || 0), 0)
        ),
    },
  ];

  return COLUMNS;
};

export default AgeColumns;
