import React, { useEffect, useState } from "react";
import DbNavigation from "./DbNavigation";
import axios from "axios";
import reqHeader from "../../helper/reqHeader";

import { convertSecondsToTime } from "./convert";
// import High from "./dummy/highLevel.json";

const getRandomColor = () => {
  const colors = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#A28DFF",
    "#FF668D",
    "#BFA64D",
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

const styles = {
  dashboard: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)", // 3 columns
    gap: "20px",
    padding: "20px",
  },
  card: (bgColor) => ({
    background: bgColor,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    padding: "28px 20px",
    textAlign: "center",
    border: "1px solid #ddd",
    transition: "transform 0.2s ease-in-out",
  }),
  cardHover: {
    transform: "translateY(-5px)",
  },
  title: {
    fontSize: "18px",
    color: "#ffffff",
    marginBottom: "10px",
  },
  value: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#ffffff",
    marginTop: "5px",
  },
};

const InfoCard = ({ title, value }) => {
  const bgColor = getRandomColor();
  return (
    <div
      style={styles.card(bgColor)}
      onMouseEnter={(e) =>
        (e.currentTarget.style.transform = styles.cardHover.transform)
      }
      onMouseLeave={(e) => (e.currentTarget.style.transform = "none")}
    >
      <h3 style={styles.title}>{title}</h3>
      <p style={styles.value}>{value}</p>
    </div>
  );
};

const HighLevelView = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  async function getData() {
    setLoading(true);
    const url = `${process.env.REACT_APP_URL}/highlevelsummary`;
    const headers = reqHeader(false, false, false);
    const config = { headers };
    const res = await axios.get(url, config);
    setData(res.data.data);
    setLoading(false);
  }

  // const data = High;

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="container entitiCustomerWrap d-flex">
        <DbNavigation />
        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser gap-4">
              <h2>High Level View</h2>
            </div>
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              {Object.keys(data).length > 0 && (
                <div style={styles.dashboard}>
                  <InfoCard
                    title="Total Audio Collected"
                    value={convertSecondsToTime(data?.totalAudioCollected)}
                  />
                  <InfoCard
                    title="Total Audio Delivered"
                    value={convertSecondsToTime(data?.totalAudioDelivered)}
                  />
                  <InfoCard
                    title="Total Speech Delivered"
                    value={convertSecondsToTime(
                      data?.totalSpeechDelivered.toFixed(2)
                    )}
                  />
                  <InfoCard
                    title="Avg Collection Speed Per Week"
                    value={convertSecondsToTime(
                      data?.avgCollectionSpeedPerWeek
                    )}
                  />
                  <InfoCard
                    title="Total Rejected Duration"
                    value={convertSecondsToTime(data?.totalRejectedDuration)}
                  />
                  <InfoCard
                    title="Last Delivery Date"
                    value={data?.lastDeliveryDate}
                  />
                  <InfoCard
                    title="Next Expected Delivery Date"
                    value={data?.nextExpectedDeliveryDate}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HighLevelView;
