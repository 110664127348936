import React, { useState } from "react";
import DbNavigation from "./DbNavigation";
import Select from "react-select";
import axios from "axios";
import reqHeader from "../../helper/reqHeader";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";

const years = Array.from({ length: 2 }, (_, i) => ({
  value: 2025 - i,
  label: `${2025 - i}`,
}));

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
].map((month, index) => ({ value: index + 1, label: month }));

const styles = {
  container: {
    minHeight: "100vh",
    backgroundColor: "#f3f4f6",
    padding: "2rem",
  },
  wrapper: {
    maxWidth: "72rem",
    margin: "0 auto",
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: "0.75rem",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    padding: "1.5rem",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1.5rem",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#1f2937",
  },
  buttonGroup: {
    display: "flex",
    gap: "0.5rem",
  },
  button: (isActive) => ({
    padding: "0.5rem 1rem",
    borderRadius: "0.5rem",
    transition: "all 0.2s",
    backgroundColor: isActive ? "#3b82f6" : "#e5e7eb",
    color: isActive ? "#ffffff" : "#374151",
    cursor: "pointer",
    border: "none",
    "&:hover": {
      backgroundColor: isActive ? "#3b82f6" : "#d1d5db",
    },
  }),
  chartContainer: {
    height: "400px",
    width: "100%",
  },
  tooltip: {
    backgroundColor: "#ffffff",
    padding: "1rem",
    borderRadius: "0.5rem",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    border: "1px solid #e5e7eb",
  },
  tooltipTitle: {
    fontWeight: "600",
  },
  tooltipText: {
    color: "#4b5563",
  },
  filterSection: {
    marginBottom: "1.5rem",
  },
  filterContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  selectContainer: {
    width: "200px",
  },
  submitButton: {
    padding: "0.5rem 1rem",
    backgroundColor: "#3b82f6",
    color: "#ffffff",
    borderRadius: "0.5rem",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.2s",
    "&:hover": {
      backgroundColor: "#2563eb",
    },
  },
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: "#e5e7eb",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#3b82f6",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#3b82f6" : "white",
    "&:hover": {
      backgroundColor: state.isSelected ? "#2563eb" : "#f3f4f6",
    },
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
    border: "1px solid #e5e7eb",
  }),
};

const WeeklyReport = () => {
  const [activeChart, setActiveChart] = useState("line");
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const handleSubmit = async () => {
    if (selectedYear && selectedMonth !== null) {
      setLoading(true);
      try {
        const url = `${process.env.REACT_APP_URL}/weeklyRecording?year=${selectedYear.value}&month=${selectedMonth.value}`;
        const config = { headers: reqHeader(false, false, false) };

        const response = await axios.get(url, config);
        if (response.data.success) {
          const formattedData = response.data.data.map((item, index, arr) => {
            const prev = arr[index - 1]?.totalDuration || 0;
            const change = prev
              ? ((item.totalDuration - prev) / prev) * 100
              : 0;
            return { ...item, change: change.toFixed(2) };
          });
          setData(formattedData);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching data", error);
        setData([]);
      } finally {
        setLoading(false);
      }
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={styles.tooltip}>
          <p style={styles.tooltipTitle}>Week {label}</p>
          <p style={styles.tooltipText}>
            Duration: {formatDuration(payload[0].value)}
          </p>
          <p style={styles.tooltipText}>Change: {payload[0].payload.change}%</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="container entitiCustomerWrap d-flex">
        <DbNavigation />
        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser gap-4">
              <h2>Weekly report</h2>
            </div>
          </div>

          <div style={styles.container}>
            <div style={styles.wrapper}>
              <div style={styles.card}>
                <div style={styles.header}>
                  <h1 style={styles.title}>Weekly Duration Analysis</h1>
                  <div style={styles.buttonGroup}>
                    <button
                      onClick={() => setActiveChart("line")}
                      style={styles.button(activeChart === "line")}
                    >
                      Line Chart
                    </button>
                    <button
                      onClick={() => setActiveChart("bar")}
                      style={styles.button(activeChart === "bar")}
                    >
                      Bar Chart
                    </button>
                  </div>
                </div>

                <div style={styles.filterSection}>
                  <div style={styles.filterContainer}>
                    <div style={styles.selectContainer}>
                      <Select
                        options={years}
                        value={selectedYear}
                        onChange={setSelectedYear}
                        placeholder="Select Year"
                        styles={selectStyles}
                      />
                    </div>
                    <div style={styles.selectContainer}>
                      <Select
                        options={months}
                        value={selectedMonth}
                        onChange={setSelectedMonth}
                        placeholder="Select Month"
                        styles={selectStyles}
                      />
                    </div>
                    <button onClick={handleSubmit} style={styles.submitButton}>
                      Submit
                    </button>
                  </div>
                </div>

                <div style={styles.chartContainer}>
                  <ResponsiveContainer width="100%" height="100%">
                    {activeChart === "line" ? (
                      <LineChart
                        data={data}
                        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                        <XAxis
                          dataKey="week"
                          label={{
                            value: "Week",
                            position: "bottom",
                            offset: -10,
                          }}
                        />
                        <YAxis
                          tickFormatter={formatDuration}
                          label={{
                            value: "Duration",
                            angle: -90,
                            position: "insideLeft",
                            offset: 0,
                          }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="totalDuration"
                          name="Total Duration"
                          stroke="#2563eb"
                          strokeWidth={2}
                          dot={{ fill: "#2563eb", r: 6 }}
                          activeDot={{ r: 8 }}
                        />
                      </LineChart>
                    ) : (
                      <BarChart
                        data={data}
                        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                        <XAxis
                          dataKey="week"
                          label={{
                            value: "Week",
                            position: "bottom",
                            offset: -10,
                          }}
                        />
                        <YAxis
                          tickFormatter={formatDuration}
                          label={{
                            value: "Duration",
                            angle: -90,
                            position: "insideLeft",
                            offset: 0,
                          }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Bar
                          dataKey="totalDuration"
                          name="Total Duration"
                          fill="#2563eb"
                          radius={[4, 4, 0, 0]}
                        />
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeeklyReport;
