import React, { useState, useImperativeHandle, useEffect } from "react";
import GenderColumns from "../DataTables/columns/GenderColumns";
import DistributionaTable from "../DataTables/DistributionaTable";

import PieData from "../Charts/PieData";

import axios from "axios";
import reqHeader from "../../../helper/reqHeader";
import Spinner from "../../utils/spinner";

const Gender = React.forwardRef(
  ({ loading, setLoading, selectedState, selectedDistrict }, ref) => {
    const [genderData, setGenderData] = useState([]);

    useImperativeHandle(ref, () => ({
      async getData() {
        try {
          setLoading(true);
          const url = `${process.env.REACT_APP_URL}/genderdistrubution`;
          const headers = reqHeader(false, false, false);
          const config = { headers };
          const res = await axios.get(url, config);
          const data = res.data.data;
          setGenderData(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      },
    }));

    const filterData = genderData.filter(
      (obj) =>
        obj.state == selectedState?.value &&
        obj.district == selectedDistrict?.value
    );

    const genderTableData =
      filterData.length > 0
        ? filterData
        : genderData?.filter((obj) => obj.state == selectedState.value);

    const data02 = [];

    genderTableData.map((units) => {
      data02.push({
        name: `Male ${units.district}`,
        value: units.gender.Male,
      });
      data02.push({
        name: `Female ${units.district}`,
        value: units.gender.Female,
      });
    });

    return (
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : genderTableData ? (
          <>
            <DistributionaTable
              filterData={genderTableData}
              columnSet={GenderColumns}
            />
            {selectedDistrict !== null ? (
              <PieData data={data02} targetHours={true} />
            ) : (
              ""
            )}
          </>
        ) : (
          <p>No data fetched yet.</p>
        )}
      </div>
    );
  }
);

export default Gender;
