import React , { useEffect, useState, useMemo } from "react";
import LeftNavigation from "./left-navigation";
import { SearchIcon } from "./icons.component";
import UserProfile from "./userProfile";
import { format } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";


const schema = yup.object().shape({
  state: yup
    .string()
    .required("State is required!"),
  district: yup
    .string()
    .required("District is required!"),
  rateupdate: yup
    .string()
    .required("Rate is required!"),
});

const AddRate = () => {

  const {
    register,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [stateWiseDistricts, setStateWiseDistricts] = useState([])


  useEffect(() => {
  
    async function getstates() {
      const url = `${process.env.REACT_APP_URL}/getstates`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("States", res.data.data);
          setStates(
            res.data.data.map(( state ) => ({ label: state, value: state }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getstates();
  }, []);

  useEffect(() => {
    async function getDistricts() {
    const url = `${process.env.REACT_APP_URL}/getdistricts`;
    const headers = reqHeader(true, true, true);
    //console.log("header", headers)
    const params={
      state:state
    };
    const config = { headers, params };
    await axios
      .get(url, config)
      .then((res) => {
        //console.log("Districts", res.data.data)
        setDistricts(
          res.data.data.map((district) => ({ label: district, value: district }))
        );
        //console.log("From useEffect languages");
      })
      .catch((err) => {});
  }
  getDistricts();
}, [state]);
  
  useEffect(() => {
      async function getStateWiseDistricts() {
      const url = `${process.env.REACT_APP_URL}/getstatewisedistricts`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params={
        state:state
      };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("Districts", res.data.data)
          setStateWiseDistricts(res.data.data);
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getStateWiseDistricts();
  }, [district]);

  const onChangeState = (e) => {
    setValue('state', e.target.value,  { shouldValidate: true })
    setState(e.target.value);
  }

  const OnChangeDistrict = (e) => {
    setValue('district', e.target.value,  { shouldValidate: true })
    setDistrict(e.target.value);
  }
  

  let columns = useMemo(
    () => [
      {
        Header: "District",
        accessor: "district",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Rate",
        accessor: "rate",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
             {row.original.rate ? row.original.rate : 200}
            </div>
          );
        },
      },
      {
        Header: "Last Updated On",
        accessor: "rateUpdatedOn",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {format(
                    new Date(row.original.rateUpdatedOn),
                    "dd/MM/yyyy"
                  )}
            </div>
          );
        },
      },
    ],
    []
  );


 
  const submitHandler = async (data) => {
    //console.log(data)
    const url = `${process.env.REACT_APP_URL}/updatedistrictrate`;
    const headers = reqHeader(true, true, true);
    const body = {
      state: data.state,
      district: data.district,
      rate: data.rateupdate
    }
    const config = { headers };
    await axios
      .put(url, body, config)
      .then((res) => {
        //console.log("res", res.data)
        if(res.data.success){
          toast.success("Rate Updated successfully.")
         
          setTimeout(()=>{
           window.location.reload();
          },3000)
        
        }
       
    })
      .catch((err) => {});
  }

    return (
      <>
        <div className="container addRatePage d-flex">
          <LeftNavigation />
            <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                add rate
              </div>

              <UserProfile />
            </div>

            <form className="form" onSubmit={handleSubmit(submitHandler)}>
              <div className="d-flex justify-between regionSelectWrap">
                <div className="regionselect d-flex">
                    <div className = {`inputWrap ${
                         errors.state ? "error" : ""
                      }`}>
                      <select
                          {...register("state")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeState(e)}
                        >
                          {}
                           <option value="">State</option>
                          {states && states.map((state)=>{
                            return <option key={state.value} value={state.value}>{state.label}</option>
                          })}
                        </select>
                        {errors.state && (
                        <span className="inputErrorMsg">
                          {errors.state?.message}
                        </span>
                      )}
                    </div>
                    <div className={`inputWrap ${errors.district ? "error" : ""}`}>
                      <select className="error"
                          {...register("district")}
                          // onChange={(e) => setValue('district', e.target.value,  { shouldValidate: true })} // Using setValue
                            onChange={(e) => OnChangeDistrict(e)}
                        >
                           <option value="">District</option>
                          {districts && districts.map((district)=>{
                            console.log("district", district)
                            return <option key={district.value} value={district.value}>{district.label}</option>
                          })}
                        </select>
                        {errors.district && (
                        <span className="inputErrorMsg">
                          {errors.district?.message}
                        </span>
                      )}
                    </div>
                    <div className={`inputWrap ${errors.rateupdate ? "error" : ""}`} style={{marginRight : "14px"}}>
                      <input
                          type="text"
                          placeholder="Rate"
                          name="rateupdate"
                          ref={register}
                        />
                        {errors.rateupdate && (
                        <span className="inputErrorMsg">
                          {errors.rateupdate?.message}
                        </span>
                      )}
                    </div>
                    <div>
                      <button className="btn adminUserBtn">Update rate</button>
                    </div>
                </div>
               
              </div>
            </form>

            <div className="addRateContent">
              <div className="projectContent">
                {/* <div className="EntitiVendorTable"> */}

                    <Table
                        columns={columns}
                        data={stateWiseDistricts}
                        count={stateWiseDistricts.length}
                        pagination={true}
                        expanded={false}
                        isHeader={true}
                        filter={true}
                        isCountDisplayed={true}
                        customText="Districts"
                      />
                {/* </div> */}
              </div>
            </div>
         
          </div>
        </div>
      </>
    );
};

export default AddRate;