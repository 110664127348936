import { convertSecondsToTime } from "../../convert";

const DistrictColumns = (data) => {
  const COLUMNS = [
    {
      Header: "State",
      Footer: "Total",
      accessor: "state",
    },
    {
      Header: "District",
      accessor: "district",
    },
    {
      Header: "Total Audio Recorded",
      accessor: "totalAudioRecorded",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data.reduce(
            (total, { totalAudioRecorded }) => (total += totalAudioRecorded),
            0
          )
        ),
    },
    {
      Header: "Total Audio Validated",
      accessor: "totalAudioValidated",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data.reduce(
            (total, { totalAudioValidated }) => (total += totalAudioValidated),
            0
          )
        ),
    },
    {
      Header: "Total Audio Segmented",
      accessor: "totalAudioSegmented",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data.reduce(
            (total, { totalAudioSegmented }) => (total += totalAudioSegmented),
            0
          )
        ),
    },
    {
      Header: "Total Audio Delivered",
      accessor: "totalAudioDelivered",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data.reduce(
            (total, { totalAudioDelivered }) => (total += totalAudioDelivered),
            0
          )
        ),
    },
    {
      Header: "Total Speech Delivered",
      accessor: "totalSpeechDelivered",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data.reduce(
            (total, { totalSpeechDelivered }) =>
              (total += totalSpeechDelivered),
            0
          )
        ),
    },
  ];

  return COLUMNS;
};

export default DistrictColumns;
