import React, { useState } from "react";
import UserProfile from "./userProfile";
import LeftNavigation from "./left-navigation";
import { ToastContainer, toast } from "react-toastify";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";

const AdminDataForm = () => {
  const [formData, setFormData] = useState({
    daysSinceLastDelivery: "",
    nextExpectedBatchDate: "",
    totalRejectedDataDuration: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error when user types
  };

    const handleSubmit = async (field) => {
    
      let url = "";
      let body = {};
      const headers = reqHeader(true, true, true);

      if (field === "daysSinceLastDelivery") {
        url = `${process.env.REACT_APP_URL}/updatenextdeliverydate`;
        body = { date: formData.daysSinceLastDelivery };
      } else if (field === "nextExpectedBatchDate") {
        url = `${process.env.REACT_APP_URL}/updatelastdeliverydate`;
        body = { date: formData.nextExpectedBatchDate };
      } else if (field === "totalRejectedDataDuration") {
        url = `${process.env.REACT_APP_URL}/updaterejectedduration`;
        body = {
          duration: parseInt(formData.totalRejectedDataDuration, 10) * 3600,
        };
      }

      const config = { headers };

      await axios.put(url, body, config).then((res) => {
            if(res.data.success){   
              toast.success(res.data.message);
            }

            }).catch((err) => console.log(err));
    };

  return (
    <div className="container d-flex">
      <LeftNavigation />
       <ToastContainer />
      <div className="pageRight">
        <div className="userSection">
          <div className="welcomeUser">Admin Data Form</div>
          <UserProfile />
        </div>
        <div style={styles.container}>
          <h2 style={styles.heading}>Admin Data Form</h2>
          <form style={styles.form}>
            <div style={styles.formGroup}>
              <label>Days Since Last Batch Delivery</label>
              <div style={styles.inputGroup}>
                <input
                  type="date"
                  name="daysSinceLastDelivery"
                  value={formData.daysSinceLastDelivery}
                  onChange={handleChange}
                  className="form-control"
                  style={styles.input}
                />
                <button
                  type="button"
                  onClick={() => handleSubmit("daysSinceLastDelivery")}
                  style={styles.button}
                >
                  Update
                </button>
              </div>
              {errors.daysSinceLastDelivery && (
                <div style={styles.error}>{errors.daysSinceLastDelivery}</div>
              )}
            </div>
            <div style={styles.formGroup}>
              <label>Next Expected Batch Date</label>
              <div style={styles.inputGroup}>
                <input
                  type="date"
                  name="nextExpectedBatchDate"
                  value={formData.nextExpectedBatchDate}
                  onChange={handleChange}
                  className="form-control"
                  style={styles.input}
                />
                <button
                  type="button"
                  onClick={() => handleSubmit("nextExpectedBatchDate")}
                  style={styles.button}
                >
                  Update
                </button>
              </div>
              {errors.nextExpectedBatchDate && (
                <div style={styles.error}>{errors.nextExpectedBatchDate}</div>
              )}
            </div>
            <div style={styles.formGroup}>
              <label>Total Rejected Data Duration (Hours)</label>
              <div style={styles.inputGroup}>
                <input
                  type="text"
                  name="totalRejectedDataDuration"
                  value={formData.totalRejectedDataDuration}
                  onChange={handleChange}
                  className="form-control"
                  style={styles.input}
                />
                <button
                  type="button"
                  onClick={() => handleSubmit("totalRejectedDataDuration")}
                  style={styles.button}
                >
                  Update
                </button>
              </div>
              {errors.totalRejectedDataDuration && (
                <div style={styles.error}>
                  {errors.totalRejectedDataDuration}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminDataForm;

const styles = {
  container: { maxWidth: "600px", margin: "auto", padding: "20px" },
  heading: { textAlign: "center", marginBottom: "20px" },
  form: { display: "flex", flexDirection: "column", gap: "15px" },
  formGroup: { display: "flex", flexDirection: "column", marginBottom: "15px" },
  inputGroup: { display: "flex", alignItems: "center", gap: "10px" },
  input: {
    padding: "8px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    flex: "1",
  },
  error: { color: "red", fontSize: "14px", marginTop: "5px" },
  button: {
    padding: "8px 12px",
    fontSize: "16px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};
