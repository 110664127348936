import React, { useEffect, useState, useRef } from "react";
import DbNavigation from "./DbNavigation";
import Select from "react-select";

import {
  Gender_Distribution,
  Age_Distribution,
  Socio_economic_status,
  Language_distribution,
  stateChartsOptions,
  districtOptions,
} from "./SelectOptions";

import Gender from "./UI/Gender";
import Age from "./UI/Age";
import Economic from "./UI/Economic";
import Language from "./UI/Language";

const chartTypes = [
  {
    label: "Gender Distribution",
    value: Gender_Distribution,
    isDisabled: false,
  },
  { label: "Age Distribution", value: Age_Distribution, isDisabled: false },
  {
    label: "Socio economic status",
    value: Socio_economic_status,
    isDisabled: false,
  },
  {
    label: "Language distribution",
    value: Language_distribution,
    isDisabled: false,
  },
];

const Charts = () => {
  const [loading, setLoading] = useState(false);

  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [selectChart, setSelectChart] = useState(null);

  const childRefs = {
    age: useRef(null),
    gender: useRef(null),
    language: useRef(null),
    status: useRef(null),
  };

  // Function to trigger fetch request in specific child components
  const handleGetData = (childKey) => {
    switch (selectChart.value) {
      case Gender_Distribution:
        childKey = "gender";
        break;
      case Age_Distribution:
        childKey = "age";
        break;
      case Socio_economic_status:
        childKey = "status";
        break;
      case Language_distribution:
        childKey = "language";
        break;
      default:
        childKey = "gender";
        break;
    }

    if (childRefs[childKey]?.current) {
      childRefs[childKey].current.getData(); // Call specific child's fetch function
    }
  };

  useEffect(() => {
    if (selectedState) {
      setFilteredDistricts(
        districtOptions.filter(
          (district) => district.state === selectedState.value
        )
      );
      setSelectedDistrict(null);
    } else {
      setFilteredDistricts([]);
    }
  }, [selectedState]);

  return (
    <>
      <div className="container entitiCustomerWrap d-flex">
        <DbNavigation />
        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser gap-4">
              <div className="d-flex flex-column container gap-2">
                <p>Types</p>
                <Select
                  options={chartTypes}
                  onChange={(option) => setSelectChart(option)}
                  isDisabled={loading}
                  placeholder="Select Chart"
                />
              </div>

              <div className="d-flex flex-column container gap-2">
                <p>State</p>
                <Select
                  options={stateChartsOptions}
                  value={selectedState}
                  onChange={(option) => setSelectedState(option)}
                  placeholder="Select State"
                />
              </div>

              <div className="d-flex flex-column container gap-2">
                <p>District</p>
                <Select
                  options={filteredDistricts}
                  value={selectedDistrict}
                  onChange={(option) => setSelectedDistrict(option)}
                  placeholder="Select District"
                  isDisabled={!selectedState}
                />
              </div>

              <div className="container">
                <button
                  onClick={handleGetData}
                  disabled={loading}
                  className="btn adminUserBtn mt-2"
                  style={{ float: "right" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          {selectChart !== null ? (
            selectChart.value == Gender_Distribution ? (
              <Gender
                ref={childRefs.gender}
                loading={loading}
                setLoading={setLoading}
                selectedState={selectedState}
                selectedDistrict={selectedDistrict}
              />
            ) : selectChart.value == Age_Distribution ? (
              <Age
                ref={childRefs.age}
                loading={loading}
                setLoading={setLoading}
                selectedState={selectedState}
                selectedDistrict={selectedDistrict}
              />
            ) : selectChart.value == Socio_economic_status ? (
              <Economic
                ref={childRefs.status}
                loading={loading}
                setLoading={setLoading}
                selectedState={selectedState}
                selectedDistrict={selectedDistrict}
              />
            ) : (
              <Language
                ref={childRefs.language}
                loading={loading}
                setLoading={setLoading}
                selectedState={selectedState}
                selectedDistrict={selectedDistrict}
              />
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Charts;
