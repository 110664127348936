import { convertSecondsToTime } from "../../convert";

const LangColumns = (data) => {
  const allLanguages = Array.from(
    new Set(data.flatMap((item) => Object.keys(item.language)))
  );

  const COLUMNS = [
    {
      Header: "State",
      accessor: "state",
      Footer: "Total",
    },
    {
      Header: "District",
      accessor: "district",
    },
    ...allLanguages.map((lang) => ({
      accessorKey: `language.${lang}`,
      accessor: `language.${lang}`,
      Header: lang,
      id: `language.${lang}`,
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data
            .map((obj) => obj.language[`${lang}`])
            .reduce((total, val) => total + (val || 0), 0)
        ),
    })),
  ];
  return COLUMNS;
};

export default LangColumns;
