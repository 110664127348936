import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  LabelList,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { convertIntoPercentage } from "../convert";

const PieData = ({ data, targetHours }) => {
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#A28DFF",
    "#FF668D",
  ];

  const labelOffsets = [];

  const totalValue = data.reduce((sum, item) => sum + item.value, 0);
  const targetedHours = 900000; //250 hours

  if (labelOffsets.length !== data.length) {
    data.forEach((_, index) => {
      labelOffsets[index] = Math.random() * 30 + 15; // Random offset between 15 and 45
    });
  }

  return (
    <ResponsiveContainer width="99%" height={600}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={120}
          labelLine={({
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            index,
          }) => {
            const RADIAN = Math.PI / 180;
            const radius = outerRadius * 1.4 + labelOffsets[index];
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
              <line
                x1={cx + outerRadius * Math.cos(-midAngle * RADIAN)}
                y1={cy + outerRadius * Math.sin(-midAngle * RADIAN)}
                x2={x}
                y2={y}
                stroke="#ccc"
                strokeWidth={1}
              />
            );
          }}
          label={({
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            value,
            index,
          }) => {
            const RADIAN = Math.PI / 180;
            const radius = outerRadius * 1.4 + labelOffsets[index];
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            // Hide labels for very small sections to avoid overlap
            if (value < 0.5) return null;

            return (
              <text
                x={x}
                y={y}
                fill={COLORS[index % COLORS.length]}
                fontSize={14}
                fontWeight="bold"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
              >
                {data[index].name} -{" "}
                {targetHours
                  ? convertIntoPercentage(value, targetedHours)
                  : convertIntoPercentage(value, totalValue)}
              </text>
            );
          }}
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>

        <LabelList dataKey="value" position="outside" />

        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieData;
