import React from 'react'
import Logo from '../../assets/images/Logo.svg';
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { Link } from "react-router-dom";

const DbNavigation = () => {
    const menuToggler = () => {
        const leftSideBar = document.querySelector(".leftSideBar")
        leftSideBar.style.display = "none"
    }
    return (
        <>
            <div className="leftSideBar">
                <div>
                    <img src={Logo} alt="Logo" className="LHSLogo" />
                    <AiOutlineCloseCircle className="closeMenu"
                        onClick={() => menuToggler()} />

                    <ul className="navigationWrap">
                        <li className="navItem">
                            <Link to="/dashboard">
                                <div className="navLabel">District</div>
                            </Link>
                        </li>
                        <li className="navItem">
                            <Link to="/charts">
                                <div className="navLabel">Distribution</div>
                            </Link>
                        </li>
                        <li className="navItem">
                            <Link to="/highlevelview">
                                <div className="navLabel">High Level </div>
                            </Link>
                        </li>
                        <li className="navItem">
                            <Link to="/weeklyreport">
                                <div className="navLabel">Weekly Report</div>
                            </Link>
                        </li>
                    </ul>

                </div>
            </div>
        </>
    )
}

export default DbNavigation
