import { convertSecondsToTime } from "../../convert";

const GenderColumns = (data) => {
  const COLUMNS = [
    {
      Header: "State",
      Footer: "Total",
      accessor: "state",
    },
    {
      Header: "District",
      accessor: "district",
    },
    {
      Header: "Male",
      accessor: "gender.Male",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data
            .map((obj) => obj.gender.Male)
            .reduce((total, val) => total + (val || 0), 0)
        ),
    },
    {
      Header: "Female",
      accessor: "gender.Female",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data
            .map((obj) => obj.gender.Female)
            .reduce((total, val) => total + (val || 0), 0)
        ),
    },
  ];

  return COLUMNS;
};

export default GenderColumns;
