export function convertSecondsToTime(seconds = 0) {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds - hours * 3600) / 60);
  let remainingSeconds = seconds - hours * 3600 - minutes * 60;

  return `${hours} : ${minutes} : ${remainingSeconds.toFixed()}`;
}

export function convertIntoPercentage(seconds = 0, hours) {
  return ((seconds / hours) * 100).toFixed(2) + "%";
}
