import React, { useEffect, useState } from "react";
import GenderData from "./dummy/genderDistribution.json";

import Select from "react-select";
import axios from "axios";
import reqHeader from "../../helper/reqHeader";

const url = `${process.env.REACT_APP_URL}/districtsummary`;
const headers = reqHeader(false, false, false);
const config = { headers };
let data = GenderData.data;

// axios
//   .get(url, config)
//   .then((res) => (data = res.data.data))
//   .catch((err) => console.log(err));

export const Gender_Distribution = "Gender_Distribution";
export const Age_Distribution = "Age_Distribution";
export const Socio_economic_status = "Socio_economic_status";
export const Language_distribution = "Language_distribution";

export const StateSelect = ({ selectState, setSelectState, type }) => {
  const stateArray = data
    .map((chart) => chart.state)
    .sort()
    .map((label) => ({ label, value: label.toLowerCase() }));
  if (type) {
    stateArray.unshift({ label: "All", value: "all" });
  }
  const stateOptions = Array.from(
    new Set(stateArray.map((o) => JSON.stringify(o)))
  ).map((str) => JSON.parse(str));

  return (
    <div className="d-flex flex-column container gap-2">
      <p>State</p>
      <Select
        defaultValue={selectState}
        onChange={setSelectState}
        options={stateOptions}
      />
    </div>
  );
};

export const stateChartsOptions = [...new Set(data.map((item) => item.state))]
  .sort()
  .map((state) => ({
    value: state,
    label: state,
  }));

export const districtOptions = data
  .map((item) => ({
    value: item.district,
    label: item.district,
    state: item.state,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));
