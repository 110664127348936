import React, { useState, useEffect } from "react";
import DbNavigation from "./DbNavigation";
import axios from "axios";
import reqHeader from "../../helper/reqHeader";

import DistributionaTable from "./DataTables/DistributionaTable";
import DistrictColumns from "./DataTables/columns/DistrictColumns";
import { StateSelect } from "./SelectOptions";

const BasicTable = () => {
  const [loading, setLoading] = useState(false);
  const [districtData, setDistrictData] = useState([]);
  const [selectState, setSelectState] = useState({
    label: "All",
    value: "all",
  });

  async function getData() {
    setLoading(true);
    const url = `${process.env.REACT_APP_URL}/districtsummary`;
    const headers = reqHeader(false, false, false);
    const config = { headers };
    const res = await axios.get(url, config);
    const data = res.data.data;
    setDistrictData(data);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  const filterData = districtData.filter(
    (obj) => obj.state == selectState.label
  );

  const districtTableData = filterData.length > 0 ? filterData : districtData;

  return (
    <>
      <div className="container entitiCustomerWrap d-flex">
        <DbNavigation />
        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser gap-4">
              <StateSelect
                selectState={selectState}
                setSelectState={setSelectState}
                type="districtSummary"
              />
            </div>
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : districtTableData ? (
            <DistributionaTable
              filterData={districtTableData}
              columnSet={DistrictColumns}
            />
          ) : (
            <p>No data fetched yet.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default BasicTable;
