import React, { useState, useImperativeHandle } from "react";
import PieData from "../Charts/PieData";

import DistributionaTable from "../DataTables/DistributionaTable";
import LangColumns from "../DataTables/columns/LangColumns";

import axios from "axios";
import reqHeader from "../../../helper/reqHeader";
import Spinner from "../../utils/spinner";

const Language = React.forwardRef(
  ({ loading, setLoading, selectedState, selectedDistrict }, ref) => {
    const [langData, setLangData] = useState([]);

    useImperativeHandle(ref, () => ({
      async getData() {
        try {
          setLoading(true);
          const url = `${process.env.REACT_APP_URL}/languagedistribution`;
          const headers = reqHeader(false, false, false);
          const config = { headers };
          const res = await axios.get(url, config);
          const data = res.data.data;
          setLangData(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      },
    }));

    const filterData = langData?.filter(
      (obj) =>
        obj.state == selectedState?.value &&
        obj.district == selectedDistrict?.value
    );

    const langTableData =
      filterData.length > 0
        ? filterData
        : langData?.filter((obj) => obj.state == selectedState.value);

    const data02 = [];

    langTableData.map((units) => {
      const { language } = units;

      Object.keys(language).map((key) =>
        data02.push({
          name: key,
          value: language[key],
        })
      );
    });

    return (
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : langTableData ? (
          <>
            <DistributionaTable
              filterData={langTableData}
              columnSet={LangColumns}
            />
            {selectedDistrict !== null ? (
              <PieData data={data02} targetHours={true} />
            ) : (
              ""
            )}
          </>
        ) : (
          <p>No data fetched yet.</p>
        )}
      </div>
    );
  }
);

export default Language;
