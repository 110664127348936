import { convertSecondsToTime } from "../../convert";

const EcoColumns = (data) => {
  const COLUMNS = [
    {
      Header: "State",
      Footer: "Total",
      accessor: "state",
    },
    {
      Header: "District",
      accessor: "district",
    },
    {
      Header: "Upper",
      accessor: "socioeconomicstatus.Upper",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data
            .map((obj) => obj.socioeconomicstatus["Upper"])
            .reduce((total, val) => total + (val || 0), 0)
        ),
    },
    {
      Header: "Upper Middle",
      accessor: "socioeconomicstatus.Upper_Middle",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data
            .map((obj) => obj.socioeconomicstatus["Upper_Middle"])
            .reduce((total, val) => total + (val || 0), 0)
        ),
    },
    {
      Header: "Upper Lower",
      accessor: "socioeconomicstatus.Upper_Lower",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data
            .map((obj) => obj.socioeconomicstatus["Upper_Lower"])
            .reduce((total, val) => total + (val || 0), 0)
        ),
    },
    {
      Header: "Middle",
      accessor: "socioeconomicstatus.Middle",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data
            .map((obj) => obj.socioeconomicstatus["Middle"])
            .reduce((total, val) => total + (val || 0), 0)
        ),
    },
    {
      Header: "Lower Middle",
      accessor: "socioeconomicstatus.Lower_Middle",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data
            .map((obj) => obj.socioeconomicstatus["Lower_Middle"])
            .reduce((total, val) => total + (val || 0), 0)
        ),
    },
    {
      Header: "Lower",
      accessor: "socioeconomicstatus.Lower",
      Cell: ({ cell: { value } }) => convertSecondsToTime(value),
      Footer: () =>
        convertSecondsToTime(
          data
            .map((obj) => obj.socioeconomicstatus["Lower"])
            .reduce((total, val) => total + (val || 0), 0)
        ),
    },
  ];

  return COLUMNS;
};

export default EcoColumns;
