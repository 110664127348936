import React , { useEffect, useState, useMemo } from "react";
import LeftNavigation from "./left-navigation";
import { SearchIcon } from "./icons.component";
import UserProfile from "./userProfile";
import Select from "react-select";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";
import Excel from "../assets/images/excel.png"

const schema = yup.object().shape({
  state: yup
    .string()
    .required("State is required!"),
  district: yup
    .string()
    .required("district is required!"),
  coordinator: yup
    .string()
    .required("Coordinator is required!"),
  role: yup
    .string()
    .required("Role is required!"),
});

const AdminAllQCStatus = () => {

  const history = useHistory();

  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinator, setCoordinator] = useState([]);
  const [userList, setUsersList] = useState([]);
  const [totalDuration, setTotalDuration] = useState();
  const [roleBaseDetails, setRoleBaseDetails] = useState({});
  const [stateFileList, setStateFileList] = useState([]);
  const [role, setRole]= useState([]);


  useEffect(() => {
  
    async function getstates() {
      const url = `${process.env.REACT_APP_URL}/getstates`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("States", res.data.data);
          setStates(
            res.data.data.map(( state ) => ({ label: state, value: state }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getstates();
  }, []);
  
  useEffect(() => {
      async function getDistricts() {
      const url = `${process.env.REACT_APP_URL}/getdistricts`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params={
        state:state
      };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("Districts", res.data.data)
          setDistricts(
            res.data.data.map((district) => ({ label: district, value: district }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getDistricts();

    async function getStateFileList(){
      const url = `${process.env.REACT_APP_URL}/getstatefilelist`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params={
        state:state
      };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("res.data.data", res.data.data.length)
          setStateFileList(res.data.data)
        })
        .catch((err) => {});
    }

    getStateFileList()
  }, [state]);

  useEffect(() => {
    
    async function getcoordinators() {
      const url = `${process.env.REACT_APP_URL}/getcoordinators`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params = {
        state:state,
        district:district,
       };

      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("Coordinator", res.data.data);
          setCoordinators(
            res.data.data.map(( coordinator ) => ({ label: coordinator.name, value: coordinator.name }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getcoordinators();

  }, [district]);

  const onChangeState = (e) => {
    setValue('state', e.target.value,  { shouldValidate: true })
    setState(e.target.value);
  }
  
  
  const OnChangeDistrict = (e) => {
    setValue('district', e.target.value,  { shouldValidate: true })
    setDistrict(e.target.value);
  }
  
  const onChangeCoordinator = (e) => {
    setValue('coordinator', e.target.value,  { shouldValidate: true })
    setCoordinator(e.target.value);

    // async function getUsers() {
    //   const url = `${process.env.REACT_APP_URL}/getcoordinatorwiseusers`;
    //   const headers = reqHeader(true, true, true);
    //   const params = {state, district,coordinator:e.target.value}
    //   const config = { params, headers };
    //   await axios
    //     .get(url, config)
    //     .then((res) => {
    //       setUsersList(res.data.data)

    //    })
    //     .catch((err) => {});
    // }
    // getUsers();

  }

  const onChangeRole = (e) => {
    setValue('role', e.target.value,  { shouldValidate: true });
    setRole(e.target.value);
  }

  useEffect(()=>{
    //console.log("role in useeffect", role)
    if(role == "Intra1"){
      async function getUsers() {
        const url = `${process.env.REACT_APP_URL}/getcoordinatorwiseusersforintra`;
        const headers = reqHeader(true, true, true);
        const params = {state, district,coordinator:coordinator}
        const config = { params, headers };
        await axios
          .get(url, config)
          .then((res) => {
            setUsersList(res.data.data)
  
         })
          .catch((err) => {});
      }
      getUsers();
    }else if(role == "Intra2"){
      async function getUsers() {
        const url = `${process.env.REACT_APP_URL}/getcoordinatorwiseusersforintra2`;
        const headers = reqHeader(true, true, true);
        const params = {state, district,coordinator:coordinator}
        const config = { params, headers };
        await axios
          .get(url, config)
          .then((res) => {
            setUsersList(res.data.data)
  
         })
          .catch((err) => {});
      }
      getUsers();
    }else if(role == "Inter1"){
      async function getUsers() {
        const url = `${process.env.REACT_APP_URL}/getinterusersampleaudio`;
        const headers = reqHeader(true, true, true);
        const params = {state, district,coordinator:coordinator}
        const config = { params, headers };
        await axios
          .get(url, config)
          .then((res) => {
            setUsersList(res.data.data)
  
         })
          .catch((err) => {});
      }
      getUsers();
    }else if(role == "Inter2"){
      async function getUsers() {
        const url = `${process.env.REACT_APP_URL}/getinter2usersampleaudio`;
        const headers = reqHeader(true, true, true);
        const params = {state, district,coordinator:coordinator}
        const config = { params, headers };
        await axios
          .get(url, config)
          .then((res) => {
            setUsersList(res.data.data)
  
         })
          .catch((err) => {});
      }
      getUsers();
    }
  },[role])

  

  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "speakerID",
        accessor: "speakerID",
        sortType: "basic",
        filter: "text",
        // Cell: ({ row }) => {
        //   return  <span style={{textDecoration: "underline #22A4EE",cursor: "pointer", color:"#22A4EE"}}
        //   onClick={() => rowClicked(row.original.mobile, row.original.name, row.original.age, row.original.gender)}>
        //   {row.original.speakerID}
        // </span>
        // },
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        //sticky: "left",
      },
      {
        Header: "Total Duration",
        accessor: "recordedHours",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Total Files",
        accessor: "",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return (
            row.original.role == "Intra1" || row.original.role == "Intra2" ? (row.original.intraTotalCount) : 1
          )   
        },
      },
      {
        Header: "Pending Files",
        accessor: "",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return (
            row.original.role == "Intra1" || row.original.role == "Intra2" ? (row.original.intraTotalCount - row.original.intraCompletedCount > 0 ? <div style={{color: "red"}}><strong>{(row.original.intraTotalCount - row.original.intraCompletedCount)}</strong></div> : <div>{(row.original.intraTotalCount - row.original.intraCompletedCount)}</div>) : row.original.role == "Inter1" || row.original.role == "Inter2" ? (row.original.inter1CheckStatus ? 0 : <div style={{color: "red"}}><strong>1</strong></div>): ""
          )   
        },
      },
      {
        Header: "Completed Files",
        accessor: "",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return (
            row.original.role == "Intra1" || row.original.role == "Intra2" ? (row.original.intraCompletedCount) : row.original.role == "Inter1" || row.original.role == "Inter2" ? (row.original.inter1CheckStatus ? 1 : 0) : ""
          )   
        },
      },
      {
        Header: "Checked By",
        accessor: "checkedBy",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return row.original.role == "Intra1" || row.original.role == "Intra2" ? (row.original.checkedBy ? "IntraCheckedBy" + " " +row.original.checkedBy : row.original.speakerCheckedBy ? "SpkcheckedBy" + " " + row.original.speakerCheckedBy : "") : row.original.interCheckByName
        },
      }
      //speakerCheckedBy
   
    ],
    []
  );

    return (
      <>
        <div className="container entitiCustomerWrap d-flex">
          <LeftNavigation />

          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Admin
              </div>

              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between regionSelectWrap">
                <div className="regionselect d-flex">
                    <div className = {`inputWrap ${
                         errors.state ? "error" : ""
                      }`}>
                      <select
                          {...register("state")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeState(e)}
                        >
                          {}
                           <option value="">Select State</option>
                          {states && states.map((state)=>{
                            return <option key={state.value} value={state.value}>{state.label}</option>
                          })}
                        </select>
                        {errors.state && (
                        <span className="inputErrorMsg">
                          {errors.state?.message}
                        </span>
                      )}
                    </div>
                     <div className={`inputWrap ${errors.district ? "error" : ""}`}>
                      <select className="error"
                          {...register("district")}
                          // onChange={(e) => setValue('district', e.target.value,  { shouldValidate: true })} // Using setValue
                            onChange={(e) => OnChangeDistrict(e)}
                        >
                           <option value="">Select District</option>
                          {districts && districts.map((district)=>{
                            //console.log("district", district)
                            return <option key={district.value} value={district.value}>{district.label}</option>
                          })}
                        </select>
                        {errors.district && (
                        <span className="inputErrorMsg">
                          {errors.district?.message}
                        </span>
                      )}
                    </div>
                    <div className = {`inputWrap ${
                         errors.coordinator ? "error" : ""
                      }`}>
                      <select
                          {...register("coordinator")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeCoordinator(e)}
                        >
                          {}
                           <option value=""> Co-ordinator</option>
                          {coordinators && coordinators.map((coordinator)=>{
                            //console.log("Option coordinator", coordinator.value)
                            return <option key={coordinator.value} value={coordinator.value}>{coordinator.label}</option>
                          })}
                        </select>
                        {errors.coordinator && (
                        <span className="inputErrorMsg">
                          {errors.coordinator?.message}
                        </span>
                      )}
                    </div>
                  <div className={`inputWrap ${errors.role ? "error" : ""}`}>
                    <select
                        {...register("role")}
                        //onChange={(e) => setValue('role', e.target.value,  { shouldValidate: true })} // Using setValue
                        onChange={(e) => onChangeRole(e)}
                      >
                        <option value="">Please select Role</option>
                        <option value="Intra1">Intra1</option>
                        <option value="Intra2">Intra2</option>
                        <option value="Inter1">Inter1</option>
                        <option value="Inter2">Inter2</option>
                      </select>
                      {errors.role && (
                        <span className="inputErrorMsg">
                          {errors.role?.message}
                        </span>
                      )}
                  </div>
                </div>
              </div>

              {/* <div className="d-flex justify-between">
                <div className="adminDetails d-flex">
                </div>
              </div> */}
            </div>
            
            <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={userList}
                      count={userList.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Speakers"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default AdminAllQCStatus;